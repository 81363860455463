<template>

    <Row v-if="ready">
      <Column :width="4"></Column>
      <Column :width="11">
        <a v-if="imageOne" v-on:click="imageOneClicked" class="clickable"><img :src="imageOne" style="height:100px" /></a>&nbsp;
        <a v-if="imageTwo" v-on:click="imageTwoClicked"  class="clickable"><img :src="imageTwo" style="height:100px" /></a>&nbsp;
        <a v-if="imageThree" v-on:click="imageThreeClicked" class="clickable"><img :src="imageThree" style="height:100px" /></a>&nbsp;
        <a v-if="imageFour" v-on:click="imageFourClicked" class="clickable"><img :src="imageFour" style="height:100px" /></a>&nbsp;
      </Column>
    </Row>

</template>

<script>

import { mapGetters } from 'vuex'

//import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Comment from "@/domain/model/comment/Comment.js";

import CommentImageDisplayRow from "./CommentImageDisplayRow.js";

export default {
  name: 'comment-image-display-cell',
  components: {
    //Box, 
    Row, Column,
  },
  props: {
    data: { type: Object, default: () => {} },
  },
  data() {
    return {
      row: new CommentImageDisplayRow(),
      ready: false,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    imageOne: function() {
      var comment = new Comment(this.domain, this.row.object());
      var imageMap = comment.images().find();
      if (imageMap.isEmpty()) {
        return null;
      }
      var images = imageMap.sortByIndex();
      if (images.length > 0) {
        var image = images[0];
        return image.imageDisplay();
      }
      return null;
    },
    imageTwo: function() {
      var comment = new Comment(this.domain, this.row.object());
      var imageMap = comment.images().find();
      if (imageMap.isEmpty()) {
        return null;
      }
      var images = imageMap.sortByIndex();
      if (images.length > 1) {
        var image = images[1];
        return image.imageDisplay();
      }
      return null;
    },
    imageThree: function() {
      var comment = new Comment(this.domain, this.row.object());
      var imageMap = comment.images().find();
      if (imageMap.isEmpty()) {
        return null;
      }
      var images = imageMap.sortByIndex();
      if (images.length > 2) {
        var image = images[2];
        return image.imageDisplay();
      }
      return null;
    },
    imageFour: function() {
      var comment = new Comment(this.domain, this.row.object());
      var imageMap = comment.images().find();
      if (imageMap.isEmpty()) {
        return null;
      }
      var images = imageMap.sortByIndex();
      if (images.length > 3) {
        var image = images[3];
        return image.imageDisplay();
      }
      return null;
    },
  },
  mounted: function () {
    this.row.data = this.data;
    this.ready = true;
  },
  
  methods: {
    imageOneClicked() {
      this.clicked(this.imageOne, 0);
    },
    imageTwoClicked() {
      this.clicked(this.imageTwo, 1);
    },
    imageThreeClicked() {
      this.clicked(this.imageThree, 2);
    },
    imageFourClicked() {
      this.clicked(this.imageFour, 3);
    },
    clicked(imageData, index) {
      if (imageData) {
        //
      }
      let data = {};
      data["field"] = this.row.field();
      data["id"] = this.row.id();
      data["object"] = this.row.object();
      data["link"] = imageData;
      data["imageIndex"] = index;
      this.$emit("click", data);
    }
  },
}
</script>