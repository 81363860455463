<template>
  <Row v-if="ready" borderStyle="rc-border-thin-even">
    <Column :width="15" :key="redraw">
      <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
        <Row>
          <Column :width="5">
            <LabelBox fontSize="xlarge">
              {{ company.name() }}
            </LabelBox>
          </Column>
          <Column :width="3">
            <Box>
              <StatusCell :data="this.companyStatusRow().data"></StatusCell>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Phone
              </template>
              <template slot="value">
                <a :href="'tel:' + company.phone()">{{  company.phone() }}</a>
              </template>
            </LabelValueBox>
          </Column>
        </Row>

        <Row v-if="row.isAdmin()"> <!-- Created and Modified By/On Row -->
          <Column :width="5">
            <Box>
              <Row>
                <LabelValueBox :labelWidth="5">
                  <template slot="label">
                    Modified By/On
                  </template>
                  <template slot="value">
                    {{ company.modifiedBy().find().fullName() }}
                    {{ company.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                  </template>
                </LabelValueBox>
              </Row>
            </Box>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Created By/On
              </template>
              <template slot="value">
                {{ company.createdBy().find().fullName() }}
                {{ company.createdDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
              </template>
            </LabelValueBox>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Contract Start
              </template>
              <template slot="value">
                {{ company.contractStartDate().displayAs_YYYY_MM_DD() }}
              </template>
            </LabelValueBox>
          </Column>
        </Row>
        
        <Row  v-if="row.isAdmin()"> <!-- Invoice Row -->
          <Column :width="5">
            <Box>
              <Row>
                <LabelValueBox :labelWidth="5">
                  <template slot="label">
                    Invoice Contact
                  </template>
                  <template slot="value">
                    {{ invoiceSettings.contactName() }}
                  </template>
                </LabelValueBox>
              </Row>
            </Box>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Invoice Phone
              </template>
              <template slot="value">
                <a :href="'tel:' + invoiceSettings.contactPhone()">{{  invoiceSettings.contactPhone() }}</a>
              </template>
            </LabelValueBox>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Invoice Email
              </template>
              <template slot="value">
                <a :href="'tel:' + invoiceSettings.contactEmail()">{{  invoiceSettings.contactEmail() }}</a>
              </template>
            </LabelValueBox>
          </Column>
        </Row>

        <Row  v-if="row.isAdmin()"> <!-- Card Row -->
          <Column :width="5">
            <Box>
              <Row>
                <LabelValueBox :labelWidth="5">
                  <template slot="label">
                    Card Holder Name
                  </template>
                  <template slot="value">
                    {{ card.name() }}
                  </template>
                </LabelValueBox>
              </Row>
            </Box>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Invoice Auto Charge/Email
              </template>
              <template slot="value">
                {{  company.autoChargeInvoice() }} / {{ company.autoEmailInvoice() }}
              </template>
            </LabelValueBox>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                QB Customer ID
              </template>
              <template slot="value">
                <a :href="'https://qbo.intuit.com/app/customerdetail?nameId=' + company.integrationReference()">
                  {{ company.integrationReference() }}
                </a>
              </template>
            </LabelValueBox>
          </Column>
        </Row>

        <Row  v-if="row.isAdmin()"> <!-- Contract Row -->
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Promo Code
              </template>
              <template slot="value">
                {{ company.promo() }} 
              </template>
            </LabelValueBox>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Discount
              </template>
              <template slot="value">
                {{ company.discount() }}
              </template>
            </LabelValueBox>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Company Id
              </template>
              <template slot="value">
                <a :href="'' + company.id()">
                  {{ company.id() }}
                </a>
              </template>
            </LabelValueBox>
          </Column>
        </Row>

        <Row v-if="row.isAdmin()"> <!-- Contract Row -->
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Credit
              </template>
              <template slot="value">
                {{ company.credit() }} 
              </template>
            </LabelValueBox>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Overpayment
              </template>
              <template slot="value">
                {{ invoiceSettings.overPaymentBalance() }}
              </template>
            </LabelValueBox>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                QB Card ID
              </template>
              <template slot="value">
                {{ invoiceSettings.cardId() }}
              </template>
            </LabelValueBox>
          </Column>
        </Row>

        <Row  v-if="row.isAdmin()"> <!-- Admin Notes -->
          <Column :width="5">
            <LabelValueBox :labelWidth="5">
              <template slot="label">
                Admin Working Name
              </template>
              <template slot="value">
                {{ company.adminSummary() }} 
              </template>
            </LabelValueBox>
          </Column>
          <Column :width="10">
            <LabelValueBox :labelWidth="2">
              <template slot="label">
                Admin Notes
              </template>
              <template slot="value">
                {{ Content.decode(adminNotes()) }}
              </template>
            </LabelValueBox>
          </Column>
        </Row>

        <!-- Admin section -->
        <Row v-if="row.isAdmin()">
          <Column :width="15">
            <Box>
              <Row> <!-- Button Row-->
                <Column :width="13"></Column>
                <!-- <Column :width="2">
                  <Button v-on:click="withInvoicesButtonPressed">Invoices</Button>
                </Column>
                <Column :width="2">
                  <Button v-on:click="withUpdateButtonPressed">Update</Button>
                </Column> -->
                <Column :width="2">
                  <Button v-on:click="withSelectButtonPressed">Select</Button>
                </Column>
              </Row><!-- End Button Row-->
            </Box>
          </Column>
        </Row>
        <!-- Admin section -->

      </Box>
    </Column>
  </Row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import LabelBox from "@/portals/shared/library/label/Label.vue";
import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";
// import ValueBox from "@/portals/shared/library/value/Value.vue";

import StatusCell from "@/portals/shared/cell/status/StatusDisplayCell";
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';

import CompanyRow from './CompanyRow';

export default {
  name: "company-cell",
  components: {
    Box, Row, Column,
    StatusCell,
    // ValueBox, 
    LabelBox, LabelValueBox,
    Button,
  },
  props: {
    showMissingRequirements: { type: Boolean, default: false },
    isAdmin: {type: Boolean, default: true},
    data: {type: Object, default: null},
  },
  data() {
    return {
      MC: new MC(),
      Connections: ConnectionUtils,
      Consts: ConstUtils,
      Content: ContentUtils,
      Strings: StringUtils,

      row: new CompanyRow(),
      company: null,
      invoiceSettings: null,
      card: null,
      ready: false,
      redraw: 1,
    }
  },
  computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'CompanyStorage_lastEvent',
                    ]),
  },
  watch: {
    CompanyStorage_lastEvent() {
      let company = this.domain.companys().findById(this.company.id());
      if (this.company.modifiedDate() < company.modifiedDate()) {
        this.row.withCompany(company);
        this.company = this.row.company(this.domain);
        this.invoiceSettings = this.company.invoiceSettings();
        this.card = this.invoiceSettings.card().find();
        this.redraw++;
      }
    }
  },
  mounted: function () {
    this.row.data = this.data;
    
    this.company = this.row.company(this.domain);
    this.invoiceSettings = this.company.invoiceSettings();
    this.card = this.invoiceSettings.card().find();
    
    this.ready = true;
  },
  methods: {
    ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),

    withEnterPressed(keyData) {
      this.$emit("withEnterPressed", keyData);
    },

    withValue(valueData) {
      this.$emit('withValue', valueData);
    },

    withUpdateButtonPressed() {
      let data = {};
      data["field"] = "Update";
      this.withButtonPressed(data);
    },

    withSelectButtonPressed() {
      let data = {};
      data["field"] = "Select";
      data["id"] = this.company.id();
      this.withButtonPressed(data);
    },

    withInvoicesButtonPressed() {
      let data = {};
      data["field"] = "Invoices";
      this.withButtonPressed(data);
    },
    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },

    companyStatusRow() {
      let row = new StatusDisplayRow();
      let now = this.domain.time().now();
      let value = this.company.state();
      let message = null;

      if (now.isGt(this.company.contractStartDate()) && this.company.hasDiscount()) {
        if (this.company.isStateActive()) {
          message = "Discount In Contract Period!!";
          value = "ExpireNext";
        }
      }

      row.withLabelWidth(0);
      row.withValueWidth(15);
      row.withValue(value);
      row.withObject(message);
      return row;
    },

    adminNotes() {
      let company = this.company;
      let notes = company.adminNotes();
      if (notes != null) {
        return notes.substring(0, notes.length > 400 ? 400 : notes.length);
      }
      return "";
    }
  }
}
</script>