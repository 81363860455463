<template>
    <Row v-if="ready">
        <Column :width="15">
            <iframe 
                :src="rowData.pdfUrl()"
                width="100%" 
                height="1000px">
            </iframe> 
        </Column>
    </Row>
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';
import MC from "@/domain/session/MC.js";
import PdfDisplayRow from "./PdfDisplayRow.js";
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';

export default {
    name: 'pdf-display-cell',
    components: {
        Row, Column,
    },
    props: {
        data: null,
    },
    data() {
        return {
            ready: false,
            rowData: new PdfDisplayRow(),
            Contents: ContentUtils,
            MC: new MC(),
        }
    },
    computed: {
        ...mapGetters([
            'domain',
        ]),
        row: function() {
            return this.rowData;
        },
    },
    mounted: function () {
        this.rowData.data = this.data;
        this.ready = true;
    },
    methods: {
        withValue: function(kvp) {
            if (kvp) {
                kvp['id'] = this.row.id();
                kvp['object'] = this.row.object();
                kvp['field'] = this.row.field();
                kvp['key'] = this.row.key();
                this.$emit('click', kvp);
            }
        },
        getClasses() {
            var value = "";
            if (this.rowData.isClickable()) {
                value = "clickable "
            }
            return value;
        },
    }
}

</script>