<template>
  <Row v-if="ready" borderStyle="rc-border-thin-even">
    <Column :width="15" :key="redraw">
      <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
        <Row>
          <Column :width="2">
            <ValueBox fontSize="medium" :align="'centre'">
              {{ invoice.number() }}
            </ValueBox>
          </Column>

          <Column :width="2">
            <ValueBox fontSize="medium" :align="'centre'">
              {{ invoice.createdDateDisplay() }}
            </ValueBox>
          </Column>

          <Column :width="2">
            <ValueBox fontSize="medium" :align="'centre'">
              {{ invoice.total().toFixed(2) }}
            </ValueBox>
          </Column>

          <Column :width="2">
            <ValueBox fontSize="medium" :align="'centre'">
              {{ invoice.totalWithTaxes().toFixed(2) }}
            </ValueBox>
          </Column>

          <Column :width="2">
            <ValueBox fontSize="medium" :align="'centre'">
              {{ invoice.totalPayments().toFixed(2) }}
            </ValueBox>
          </Column>
          <Column :width="2">
            <ValueBox fontSize="medium" :align="'centre'">
              {{ invoice.totalRemaining().toFixed(2) }}
            </ValueBox>
          </Column>

          <Column :width="2">
            <ValueBox fontSize="medium" :align="'centre'">
              {{ invoice.dueDateDisplay() }}
            </ValueBox>
          </Column>
          
          <Column :width="1">
            <Box>
              <StatusCell :data="this.invoiceStatusRow().data"></StatusCell>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column :width="9">
          </Column>
          
          <Column v-if="invoice.paid()" :width="2"></Column>
          <Column v-else :width="2">
            <Button v-on:click="withPayButtonPressed">Pay</Button>
          </Column>

          <Column :width="2">
            <Button v-on:click="withDownloadButtonPressed">Download/Print</Button>
          </Column>
          <Column :width="2">
            <Button v-on:click="withDetailsButtonPressed">Details</Button>
          </Column>
        </Row>
        <Row v-if="invoice.paid()">
          <Column class="rc-text-right rc-text-value rc-text-small">
            On {{  invoice.paidDate().displayAs_YYYY_MM_DD() }}
          </Column>
        </Row>

        <Row v-if="row.isAdmin()">
          <Column :width="15">
            <Box :even="row.even()">
              <Row>
                <Column>Admin Section</Column>
              </Row>

              <Row>
                <Column :width="5">
                    <LabelValueBox :labelWidth="5">
                        <template slot="label">
                        Company Name
                        </template>
                        <template slot="value">
                        {{ this.Content.decodeText(company.name(), "[Not Set]") }} {{ company.id() }}
                        </template>
                    </LabelValueBox>
                </Column>
                <Column :width="5">
                </Column>
              </Row>

              <Row>
                <Column :width="5">
                  <Box>
                    <Row>
                      <LabelValueBox :labelWidth="5">
                        <template slot="label">
                          Modified By/On
                        </template>
                        <template slot="value">
                          {{ invoice.modifiedBy().find().fullName() }}
                          {{ invoice.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                        </template>
                      </LabelValueBox>
                    </Row>
                  </Box>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Created By/On
                    </template>
                    <template slot="value">
                      {{ invoice.createdBy().find().fullName() }}
                      {{ invoice.createdDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Invoice Id
                    </template>
                    <template slot="value">
                      {{ invoice.id() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>

              <Row>
                <Column :width="5">
                  <Box>
                    <Row>
                      <LabelValueBox :labelWidth="5">
                        <template slot="label">
                          Promo
                        </template>
                        <template slot="value">
                          {{ company.promo() }}
                        </template>
                      </LabelValueBox>
                    </Row>
                  </Box>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Amounts
                    </template>
                    <template slot="value">
                      {{ "30%=$" + (invoice.total() * 0.3).toFixed(2) + " 20%=$" + (invoice.total() * 0.2).toFixed(2) + " 10%=$" + (invoice.total() * 0.1).toFixed(2) }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Auto Charge / Invoice
                    </template>
                    <template slot="value">
                      {{  company.autoChargeInvoice() }} /
                      {{  company.autoEmailInvoice() }} 
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>

              <Row> <!-- Card Row -->
                <Column :width="5">
                  <Box>
                    <Row>
                      <LabelValueBox :labelWidth="5">
                        <template slot="label">
                          Card Holder Name
                        </template>
                        <template slot="value">
                          {{ card.name() }}
                        </template>
                      </LabelValueBox>
                    </Row>
                  </Box>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Card No.
                    </template>
                    <template slot="value">
                      {{  card.number() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      QB Invoice / Charge ID
                    </template>
                    <template slot="value">
                      {{  invoice.integrationReference() }} /
                      {{  invoice.chargeId() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>
              
              <Row>
                <Column :width="4">
                  <Box>
                    <Row :even="row.notEven()">
                      <Column :even="row.even()">
                        <LabelBox :align="'center'">Invoice Email Sent On</LabelBox>
                        <Box>
                          <StatusCell :data="this.emailStatusRow().data"></StatusCell>
                        </Box>
                        <Button v-on:click="withSendInvoiceButtonPressed">Send Invoice Ready Email</Button>
                      </Column>
                    </Row>
                  </Box>
                </Column>
                <Column :width="4">
                  <Box>
                    <Row :even="row.notEven()">
                      <Column :even="row.even()">
                        <LabelBox :align="'center'">Charge Status</LabelBox>
                        <Box>
                          <StatusCell :data="this.chargeStatusRow().data"></StatusCell>
                        </Box>
                        <Button v-if="this.invoiceSettings.hasCard()" v-on:click="withChargeCardButtonPressed">Charge Card</Button>
                        <Box><Row><Column></Column></Row></Box>
                        <Button v-on:click="withRefreshChargeButtonPressed">Refresh</Button>
                      </Column>
                    </Row>
                  </Box>
                </Column>
                <Column :width="4">
                <Box>
                    <Row :even="row.notEven()">
                      <Column :even="row.even()">
                        <LabelBox :align="'center'">Paid On</LabelBox>
                        <Box>
                          <StatusCell :data="this.paidStatusRow().data"></StatusCell>
                        </Box>
                        <Button v-if="invoice.paid()" v-on:click="withMarkNotPaidButtonPressed">Mark Not Paid</Button>
                        <Button v-else v-on:click="withMarkPaidButtonPressed">Mark Paid</Button>
                      </Column>
                    </Row>
                  </Box>
                </Column>
                <Column :width="3">
                <Box>
                    <Row :even="row.notEven()">
                      <Column :even="row.even()">
                        <LabelBox :align="'center'">Thank-you Sent On</LabelBox>
                        <Box>
                          <StatusCell :data="this.thankyouStatusRow().data"></StatusCell>
                        </Box>
                        <Button v-on:click="withSendThankyouButtonPressed">Send Thank-you Email</Button>
                      </Column>
                    </Row>
                  </Box>
                </Column>
              </Row>

              <Row>
                <Column :width="3"></Column>
                <Column :width="2">
                  <Button v-on:click="withUpdateInvoiceButtonPressed">Update Invoice</Button>
                </Column>
                <Column :width="2">
                  <Button v-on:click="withUpdatePdfButtonPressed">Update Pdf</Button>
                </Column>
                <Column :width="2">
                  <Button v-on:click="withUpdateQuickBooksButtonPressed">Update QuickBooks</Button>
                </Column>
                <Column :width="2">
                  <Button v-on:click="withDetailsButtonPressed">Details</Button>
                </Column>
                <Column :width="2">
                  <Button v-on:click="withDownloadButtonPressed">View Pdf</Button>
                </Column>
                <Column :width="2">
                  <Button v-on:click="withSelectButtonPressed">Select</Button>
                </Column>
              </Row>
            </Box>
          </Column>
        </Row>


      </Box>
    </Column>
  </Row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Button from "@/portals/shared/library/button/Button.vue";

import StatusCell from "@/portals/shared/cell/status/StatusDisplayCell";
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';

import LabelBox from "@/portals/shared/library/label/Label.vue";
import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";
import ValueBox from "@/portals/shared/library/value/Value.vue";

import InvoiceRow from './InvoiceRow';

export default {
  name: "invoice-cell",
  components: {
    Box, Row, Column,
    StatusCell,
    ValueBox, LabelBox, LabelValueBox,
    Button,
  },
  props: {
    showMissingRequirements: { type: Boolean, default: false },
    isAdmin: {type: Boolean, default: true},
    data: {type: Object, default: null},
  },
  data() {
    return {
      MC: new MC(),
      Connections: ConnectionUtils,
      Consts: ConstUtils,
      Content: ContentUtils,
      Strings: StringUtils,

      row: new InvoiceRow(),
      invoice: null,
      company: null,
      card: null,
      invoiceSettings: null,
      ready: false,
      redraw: 1,
    }
  },
  computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'InvoiceStorage_lastEvent',
                    ]),
  },
  watch: {
    InvoiceStorage_lastEvent() {
      let invoice = this.domain.invoices().findById(this.invoice.id()).copy();
      if (this.invoice.modifiedDate() < invoice.modifiedDate()) {
        this.row.withInvoice(invoice);
        this.invoice = this.row.invoice(this.domain);
        this.company = this.invoice.company().find();
        this.invoiceSettings = this.company.invoiceSettings().find();
        this.card = this.invoiceSettings.card().find();
        this.redraw++;
      }
    }
  },
  mounted: function () {
    this.row.data = this.data;
    this.invoice = this.row.invoice(this.domain);
    this.company = this.invoice.company().find();
    this.invoiceSettings = this.company.invoiceSettings().find();
    this.card = this.invoiceSettings.card().find();
    this.ready = true;
  },
  methods: {
    ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),

    withEnterPressed(keyData) {
      this.$emit("withEnterPressed", keyData);
    },

    withValue: function (kvp) {
      this.$emit('withValue', kvp);
    },
    
    withSendInvoiceButtonPressed() {
      let data = {};
      data["field"] = "InvoiceReady";
      this.withButtonPressed(data);
    },

    withChargeCardButtonPressed() {
      let data = {};
      data["field"] = "ChargeCard";
      this.withButtonPressed(data);
    },

    withRefreshChargeButtonPressed() {
      let data = {};
      data["field"] = "RefreshChargeDetails";
      this.withButtonPressed(data);
    },

    withSendThankyouButtonPressed() {
      let data = {};
      data["field"] = "InvoicePaid";
      this.withButtonPressed(data);
    },

    withMarkPaidButtonPressed() {
      let data = {};
      data["field"] = "MarkPaid";
      this.withButtonPressed(data);
    },

    withMarkNotPaidButtonPressed() {
      let data = {};
      data["field"] = "MarkNotPaid";
      this.withButtonPressed(data);
    },

    withDownloadButtonPressed() {
      let data = {};
      data["field"] = "Pdf";
      this.withButtonPressed(data);
    },

    withPayButtonPressed() {
      let data = {};
      data["field"] = "Pay";
      this.withButtonPressed(data);
    },

    withDetailsButtonPressed() {
      let data = {};
      data["field"] = "Details";
      this.withButtonPressed(data);
    },

    withUpdateInvoiceButtonPressed() {
      let data = {};
      data["field"] = "UpdateInvoice";
      this.withButtonPressed(data);
    },

    withUpdatePdfButtonPressed() {
      let data = {};
      data["field"] = "UpdatePdf";
      this.withButtonPressed(data);
    },

    withUpdateQuickBooksButtonPressed() {
      let data = {};
      data["field"] = "UpdateQb";
      this.withButtonPressed(data);
    },

    withSelectButtonPressed() {
      let data = {};
      data["field"] = "Select";
      data["id"] = this.company.id();
      this.withButtonPressed(data);
    },

    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },

    invoiceStatusRow() {
      let now = this.domain.time().now();
      let row = new StatusDisplayRow();
      let invoice = this.invoice;
      let dueDate = invoice.dueDate();
      let paidValue = "notpaid";
      
      if (invoice.paid()) {
        paidValue = "paid";
      } else {
        if (now.time() > dueDate.time()) {
          paidValue = "pastdue"
        } else if (dueDate.minus(1).time() < now.time()) {
          paidValue = "notpaid1day";
        } else if (dueDate.minus(5).time() < now.time()) {
          paidValue = "notpaid1week";
        } else {
          paidValue = "notpaid";
        }
      }
      row.withLabelWidth(0);
      row.withValueWidth(15);
      row.withValue(paidValue);
      return row;
    },

    emailStatusRow() {
      let row = new StatusDisplayRow();
      row.withValue(this.invoice.hasSentOn() ? "Sent" : "NotSent");
      row.withObject(this.invoice.sentOn().displayAs_YYYY_MM_DD_HH_MM_SS_A());
      row.withLabelWidth(0);
      return row;
    },

    chargeStatusRow() {
      let company = this.invoice.company().find();
      let invoiceSettings = company.invoiceSettings().find();
      let row = new StatusDisplayRow();
      row.withValue(this.chargeStatus(this.invoice, invoiceSettings));
      row.withObject(this.invoice.hasChargeStatus() ? this.invoice.chargeStatus() : "None");
      row.withLabelWidth(0);
      return row;
    },

    paidStatusRow() {
      let row = new StatusDisplayRow();
      let value = this.invoice.paidDate().displayAs_YYYY_MM_DD_HH_MM_SS_A();
      if (!this.invoice.paid()) {
        value = "";
      }
      row.withValue(this.invoice.paid() ? "Sent" : "NotSent").withObject(value);
      row.withLabelWidth(0);
      return row;
    },

    thankyouStatusRow() {
      let row = new StatusDisplayRow();
      row.withValue(this.invoice.hasEmailedOn() ? "Sent" : "NotSent").withObject(this.invoice.sentOnPaid().displayAs_YYYY_MM_DD_HH_MM_SS_A());
      row.withLabelWidth(0);
      return row;
    },

    chargeStatus(invoice, invoiceSettings) {
      if (invoiceSettings) {
        //
      }
      if (invoice.hasChargeStatus()) {
        if (invoice.isChargeStatusGood()) {
          return "Captured"
        }
        if (invoice.isChargeStatusBad()) {
          return "Failed"
        }
      }
      return "Blank";
    }
  }
}
</script>