<template>
    <Row v-if="ready" :class="rowData.even() ? 'rowEven' : 'rowOdd'">
        <Column :width="15">
            <Box>
                <Row>
                    <Column>
                        <LabelValueBox v-if="ready" :class="rowData.even() ? 'rowEven' : 'rowOdd'" :labelWidth="3">
                            <template slot="label">{{ MC.Inspection.Details.SignatureLabel.value() }}</template>
                            <template slot="value" v-if="rowData.isSigned()">
                                <img width="200px" :src="rowData.image()" />
                            </template>
                        </LabelValueBox>
                    </Column>
                </Row>
                <Row>
                    <Column :width="3"></Column>
                    <Column :width="12">
                        <Box v-if="showMissingRequirements && this.rowData.mandatoryValue() && !rowData.isSigned()">
                            <Row>
                                <Column :width="15">
                                    <Box>
                                        <ValueRow class="rc-background-minor rc-font-large" fontColour="light" >Signature required. Please sign the repair to complete it.</ValueRow>
                                    </Box>
                                </Column>
                            </Row>
                        </Box>
                    </Column>
                </Row>
                <Row>
                    <Column :width="13"></Column>
                    <Column :width="2">
                        <Button v-on:click="withSignButtonPressed()">Sign</Button>
                    </Column>
                </Row>
            </Box>
        </Column>
    </Row>
          
  </template>
  
  <script>
  
  import { mapGetters } from 'vuex'
  
  import ContentUtils from '@/utils/ContentUtils.js';
  import StringUtils from '@/utils/StringUtils.js';

  import SignatureUpdateRow from "./SignatureUpdateRow.js";

  import Box from "@/portals/shared/library/box/Box.vue";
  import Button from "@/portals/shared/library/button/Button.vue";
  import Row from "@/portals/shared/library/box/Row.vue";
  import Column from "@/portals/shared/library/box/Column.vue";
  import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";
  import ValueRow from '@/portals/shared/library/value/ValueRow.vue';
  import MC from "@/domain/session/MC.js";
  
  export default {
    name: 'signature-update-cell',
    components: {
      Box, Row, Column,
      Button,
      LabelValueBox,
      ValueRow,
    },
    props: {
      css:    { type: String, default: "" },
      showMissingRequirements: { type: Boolean, default: false },
      data: null,
    },
    data() {
      return {
        ready: false,
        rowData: new SignatureUpdateRow(),
        Contents: ContentUtils,
        MC: new MC(),
      }
    },
    computed: {
      ...mapGetters([
                     'domain',
                   ]),
      row: function() {
        return this.rowData;
      },
    },
    mounted: function () {
      this.rowData.data = this.data;
      this.ready = true;
    },
    methods: {
      withValue: function(kvp) {
        if (kvp) {
          kvp['id'] = this.row.id();
          kvp['object'] = this.row.object();
          this.$emit('withValue', kvp);
        }
      },

      withSignButtonPressed: function() {
        let data = {}
        data["field"] = "Sign"
        this.withButtonPressed(data)
      },

      withButtonPressed: function (buttonData) {
        if (StringUtils.isEmpty(buttonData["id"])) {
          buttonData["id"] = this.row.id();
        }
        buttonData["key"] = this.row.key();
        this.$emit('click', buttonData);
        this.$emit('withButtonPressed', buttonData);
      },
    },
  }
  </script>