
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Card from "@/domain/model/invoice/Card.js";

export default class CardRow extends RcTableViewRow {

  static Id = "CardRow";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, CardRow.Id);
  }

  withCard(item) {
    this.withObject(item.data);
    this.withId(item.id());
    this.withKey(item.modelName());
    return this;
  }

  card(domain) {
    return new Card(domain, this.object());
  }
}