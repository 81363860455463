
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ScheduleQuestionOptionUpdateRow extends RcTableViewRow {

  static Id = "ScheduleQuestionOptionUpdateRow";
  
  static INDEX = "index";
  static INITIAL = "initial";
  static CATEGORY = "category";
  static REPAIRED = "repaired";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ScheduleQuestionOptionUpdateRow.Id);
    this.withRepaired(false);
    this.withInitial(true);
  }
  
  withLabel(label) {
    this.withName(label).done();
    return this;
  }
  
  label() {
    return this.name();
  }
  
  
  index() {
    return this.getString(ScheduleQuestionOptionUpdateRow.INDEX);
  }
  
  withIndex(index) {
    this.put(ScheduleQuestionOptionUpdateRow.INDEX, index);
    return this;
  }
  
  withInitial(initial) {
    this.put(ScheduleQuestionOptionUpdateRow.INITIAL, initial);
    return this;
  }
  
  isInitial() {
    return this.getBoolean(ScheduleQuestionOptionUpdateRow.INITIAL);
  }
  
  withCategory(category) {
    this.put(ScheduleQuestionOptionUpdateRow.CATEGORY, category);
    return this;
  }

  category() {
    return this.getString(ScheduleQuestionOptionUpdateRow.CATEGORY);
  }
  
  hasInitialMinor() {
    var value = false;
    if (this.isInitial()) {
      if (this.category() === "minor") {
        value = true;
      }
    }
    return value;
  }
  
  hasInitialMajor() {
    var value = false;
    if (this.isInitial()) {
      if (this.category() === "major") {
        value = true;
      }
    }
    return value;
  }

  hasEnrouteMinor() {
    var value = false;
    if (!this.isInitial()) {
      if (this.category() === "minor") {
        value = true;
      }
    }

    return value;
  }
  
  hasEnrouteMajor() {
    var value = false;
    if (!this.isInitial()) {
      if (this.category() === "major") {
        value = true;
      }
    }
    return value;
  }
  
  isRepaired() {
    return this.getBoolean(ScheduleQuestionOptionUpdateRow.REPAIRED);
  }
  
  withRepaired(repaired) {
    this.put(ScheduleQuestionOptionUpdateRow.REPAIRED, repaired);
    return this;
  }
}