
import RcObject from "@/domain/common/RcObject.js";

export default class RcTableViewRow extends RcObject {

  static Value = "value";
  static TypeId = "typeId";
  static Name = "name";
  static Hint = "hint";
  static Object = "object";
  static Field = "field";
  static Kids = "children";
  static Key = "key"
  static Mandatory = "mandatory";
  static width = "width";
  static Even = "even";
  static PrimaryBackgroundColour = "primaryBackgroundColour";
  static BorderStyle = "borderStyle";
  static BorderRadius = "borderRadius";
  static Elevation = "elevation";
  static Selected = "selected";
  static ShowLabel = "showLabel";
  static LabelWidth = "labelWidth";
  static ValueWidth = "valueWidth";
  static FontSize = "fontSize";
  static IsAdmin = "isAdmin";
  static IsEven = "isEven";
  static Spacer = "spacer";
  static Index = "index";

  constructor() {
    super();
    this.withMandatoryValue(false);
    this.put("Even", false);
    this.withChildren([]);
    this.withShowLabel(true);
    this.withLabelWidth("4");
    this.withValueWidth("10");
  }

  withShowLabel(show) {
    this.put(RcTableViewRow.ShowLabel, show);
    return this;
  }

  showLabel() {
    return this.getBoolean(RcTableViewRow.ShowLabel);
  }

  withLabelWidth(width) {
    this.put(RcTableViewRow.LabelWidth, width);
    return this;
  }

  labelWidth() {
    return this.getInt(RcTableViewRow.LabelWidth);
  }

  fontSize() {
    return this.getString(RcTableViewRow.FontSize);
  }

  withFontSize(fontSize) {
    this.put(RcTableViewRow.FontSize, fontSize);
    return this;
  }

  withValueWidth(width) {
    this.put(RcTableViewRow.ValueWidth, width);
    return this;
  }

  valueWidth() {
    return this.getInt(RcTableViewRow.ValueWidth);
  }

  withSelected(selected) {
    this.put(RcTableViewRow.Selected, selected);
    return this;
  }

  isSelected() {
    return this.getBoolean(RcTableViewRow.Selected);
  }


  withKey(key) {
    this.put(RcTableViewRow.Key, key);
    return this;
  }

  key() {
    return this.getString(RcTableViewRow.Key)
  }

  withField(field) {
    this.put("field", field);
    return this;
  }
  
  field() {
    return this.getString("field");
  }

  withPlaceholder(placeholder) {
    this.put("placeholder", placeholder);
    return this;
  }
  
  placeholder() {
    return this.getString("placeholder");
  }

  withDefaultValue(defaultValue) {
    this.put("defaultValue", defaultValue);
    return this;
  }
  
  defaultValue() {
    return this.get("defaultValue");
  }
  
  withMandatoryValue(mandatory) {
    this.put("mandatory", mandatory);
    return this;
  }
  
  mandatoryValue() {
    return this.getBoolean("mandatory");
  }
  
  withObject(object) {
    this.put("object", object);
    return this;
  }
  
  object() {
    return this.get("object");
  }
  
  withWidth(width) {
    this.put("width", width);
    return this;
  }
  
  width() {
    return this.getInt("width");
  }
  
  widthAsString() {
    return this.getString("width");
  }
  
  withId(id) {
    super.withId(id);
    return this;
  }
  
  withName(name) {
    this.put("name", name);
    return this;
  }
  
  name() {
    return this.getString("name");
  }
  
  withValue(value) {
    this.put("value", value);
    return this;
  }
  
  valueAsObject() {
    let value = this.get("value");
    return value;
  }

  value() {
    return this.getString("value");
  }

  withHint(hint) {
    this.put("hint", hint);
    return this;
  }

  withHintUrl(hint) {
    this.put("hintUrl", hint);
    return this;
  }

  hintUrl() {
    return this.getString("hintUrl");
  }
  
  hint() {
    return this.getString("hint");
  }
  
  withEven(even) {
    this.put(RcTableViewRow.IsEven, even);
    return this;
  }
  
  even() {
    return this.getString(RcTableViewRow.IsEven);
  }
  
  notEven() {
    let value = this.getBoolean(RcTableViewRow.IsEven);
    return "" + !value;
  }
  
  withIsAdmin(even) {
    this.put(RcTableViewRow.IsAdmin, even);
    return this;
  }
  
  isAdmin() {
    return this.getBoolean(RcTableViewRow.IsAdmin);
  }

  withChildren(kids) {
    this.put(RcTableViewRow.Kids, kids);
    return this;
  }

  addChild(kid) {
    return this.withChild(kid);
  }

  withChild(kid) {
    var kids = this.children();
    if (kid != null) {
      kids.push(kid);
      this.withChildren(kids);
    }
    return this;
  }

  children() {
    return this.get(RcTableViewRow.Kids);
  }

  hasChildren() {
    return this.children().length > 0;
  }
  
  withPrimaryBackgroundColour() {
    this.put(RcTableViewRow.PrimaryBackgroundColour, "primary-component-background-colour");
    return this;
  }

  backgroundColour() {
    if (this.even()) {
      return "rowEven"
    }
    return this.getString(RcTableViewRow.PrimaryBackgroundColour);
  }

  withBorderStyleLarge() {
    this.put(RcTableViewRow.BorderStyle, "rc-border-large");
    return this;
  }

  withBorderStyleThin() {
    this.put(RcTableViewRow.BorderStyle, "rc-border-thin");
    return this;
  }

  borderStyle() {
    var borderStyle = this.getString(RcTableViewRow.BorderStyle);
    if (this.even()) {
      borderStyle = borderStyle + "-even";
    }
    return borderStyle;
  }

  withSpacer(spacer) {
    this.put(RcTableViewRow.Spacer, spacer);
    return this;
  }

  spacer() {
    return this.getBoolean(RcTableViewRow.Spacer);
  }

  withIndex(index) {
    this.put(RcTableViewRow.Index, index);
    return this;
  }

  index() {
    return this.getInt(RcTableViewRow.Index);
  }

  withBorderRadiusSmall() {
    this.put(RcTableViewRow.BorderRadius, "sm");
    return this;
  }
  withBorderRadiusMedium() {
    this.put(RcTableViewRow.BorderRadius, "md");
    return this;
  }
  withBorderRadiusLarge() {
    this.put(RcTableViewRow.BorderRadius, "lg");
    return this;
  }
  withBorderRadiusXLarge() {
    this.put(RcTableViewRow.BorderRadius, "xl");
    return this;
  }
  withBorderRadiusMax() {
    this.put(RcTableViewRow.BorderRadius, "max");
    return this;
  }
  borderRadius() {
    return this.getString(RcTableViewRow.BorderRadius);
  }
  withBorderRadiusNone() {
    this.put(RcTableViewRow.BorderRadius, "none");
    return this;
  }
  withElevationMedium() { 
    this.put(RcTableViewRow.Elevation, "md");
    return this;
  }
  withElevationLarge() { 
    this.put(RcTableViewRow.Elevation, "lg");
    return this;
  }
  withElevationXLarge() { 
    this.put(RcTableViewRow.Elevation, "xl");
    return this;
  }
  elevation() {
    return this.getString(RcTableViewRow.Elevation);
  }
  withElevationNone() {
    this.put(RcTableViewRow.Elevation, "none");
    return this;
  }
}
