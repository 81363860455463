<template>
  <Row v-if="ready" borderStyle="rc-border-thin-even">
    <Column :width="15" :key="redraw">
      <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
        <Row>
          <Column :width="10">
            <Box>
              <Row>
                <Column :width="8">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Date
                    </template>
                    <template slot="value">
                      {{inspection.createdDateDisplay()}}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="7">
                  <LabelValueBox v-if="vehicle.isEquipment()" :labelWidth="5">
                    <template slot="label">
                      Equipment
                    </template>
                    <template slot="value">
                      {{ vehicle.name() }}
                    </template>
                  </LabelValueBox>
                  <LabelValueBox v-else :labelWidth="5">
                    <template slot="label">
                      Vehicle
                    </template>
                    <template slot="value">
                      {{ vehicle.name() + " (" + vehicle.plate() + ")" }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>
              <Row>
                <Column :width="8">
                  <Box>
                    <StatusCell :data="overallStatusRow().data" />
                  </Box>
                </Column>
                <Column :width="7">
                  <Box>
                    <StatusCell :data="inspectionStatusRow().data" />
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column :width="8">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Inspected By
                    </template>
                    <template slot="value">
                      {{ inspection.inspector().find().user().find().fullName() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="7">
                  <LabelValueBox v-if="!vehicle.isEquipment()" :labelWidth="5">
                    <template slot="label">
                      Odometer
                    </template>
                    <template slot="value">
                      {{ inspection.odometer() }}  {{ vehicle.odometerType() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>
              <Row v-if="!vehicle.isEquipment()">
                <Column :width="8">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Driven By
                    </template>
                    <template slot="value">
                      {{ inspection.driver().find().user().find().fullName() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="7"></Column>
              </Row>
              <Row>
                <Column :width="8">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Trip Type
                    </template>
                    <template slot="value">
                      {{ inspection.tripDisplay() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="7">
                  <LabelValueBox :labelWidth="5" v-if="!vehicle.isEquipment()">
                    <template slot="label">
                      Work Orders
                    </template>
                    <template slot="value">
                      {{ inspection.workOrderDisplay() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>
            </Box>
          </Column>
          <Column :width="5">
            <Box v-for="trailer in inspection.trailers().find().sortByName()" :key="trailer.id()">
              <Row>
                <Column :width="15">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Trailer
                    </template>
                    <template slot="value">
                      {{ trailer.name() + " (" + trailer.plate() + ")" }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>
              <Row>
                <Column :width="15">
                  <Box>
                    <StatusCell :data="trailerDefectStatusRow(trailer).data"></StatusCell>
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column :width="15">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Attached
                    </template>
                    <template slot="value">
                      {{ inspection.results().find().forVehicle(trailer).isAttached() ? "Yes" : "No" }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column :width="8"></Column>
          <Column v-if="!inspection.hasUnfixedDefect()" :width="1"></Column>
          <Column :width="1">
            <Button v-on:click="withDetailsButtonPressed">Details</Button>
          </Column>
          <Column v-if="inspection.hasUnfixedDefect()" :width="1">
            <Button v-on:click="withRepairButtonPressed">Repair</Button>
          </Column>
          <Column :width="2">
            <Button v-on:click="withPrintButtonPressed">Print/Download</Button>
          </Column>
          <Column :width="1">
            <Button v-on:click="withEmailButtonPressed">Email</Button>
          </Column>
          <Column :width="2">
            <Button v-on:click="withRemarksButtonPressed">+ Remarks</Button>
          </Column>
        </Row>

        <!-- Admin section -->
        <Row v-if="row.isAdmin()">
          <Column :width="15">
            <Box :even="row.even()">
              <Row>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Admin Section
                    </template>
                    <template slot="value">
                      {{ "" }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>
              <Row>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Company Name
                    </template>
                    <template slot="value">
                      {{ this.Content.decodeText(company.name(), "[Not Set]") }} {{ company.id() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>

              <Row> <!-- Created and Modified By/On Row -->
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Modified By/On
                    </template>
                    <template slot="value">
                      {{ inspection.modifiedBy().find().fullName() }}
                      {{ inspection.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Created By/On
                    </template>
                    <template slot="value">
                      {{ inspection.createdBy().find().fullName() }}
                      {{ inspection.createdDate().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Inspection ID
                    </template>
                    <template slot="value">
                      {{ inspection.id() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>
              <Row>
              </Row>
              <Row> <!-- Button Row-->
                <Column :width="9"></Column>
                <Column :width="1">
                  <Button v-on:click="withDeleteButtonPressed">Delete</Button>
                </Column>
                <Column :width="1"></Column>
                <Column :width="2">
                  <Button v-on:click="withRebuildButtonPressed">Rebuild PDF</Button>
                </Column>
                <Column :width="2">
                  <Button v-on:click="withSelectButtonPressed">Select</Button>
                </Column>
              </Row><!-- End Button Row-->
            </Box>
          </Column>
        </Row>
        <!-- Admin section -->
      </Box>
    </Column>
  </Row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";

import StatusCell from '@/portals/shared/cell/status/StatusDisplayCell';
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';
import InspectionRow from './InspectionRow';

export default {
  name: "inspection-cell",
  components: {
    Box, Column, Row,
    LabelValueBox, Button,
    StatusCell,
  },
  props: {
        showMissingRequirements: { type: Boolean, default: false },
        isAdmin: { type: Boolean, default: true },
        data: { type: Object, default: null },
  },
  data() {
        return {
            MC: new MC(),
            Connections: ConnectionUtils,
            Consts: ConstUtils,
            Content: ContentUtils,
            Strings: StringUtils,

            row: new InspectionRow(),
            inspection: null,
            company: null,
            vehicle: null,

            ready: false,
            redraw: 1,
        };
    },
  computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'InspectionStorage_lastEvent',
                    ]),
  },
  watch: {
        InspectionStorage_lastEvent() {
            let inspection = this.domain.inspections().findById(this.inspection.id());
            if (this.inspection.modifiedDate() < inspection.modifiedDate()) {
                this.row.withInspection(inspection);
                this.inspection = this.row.inspection(this.domain);
                this.company = this.inspection.company().find();
                this.vehicle = this.inspection.vehicle().find();
                this.redraw++;
            }
        }
    },
  mounted: function () {
    this.row.data = this.data;
    this.inspection = this.row.inspection(this.domain);
    this.company = this.inspection.company().find();
    this.vehicle = this.inspection.vehicle().find();
    this.ready = true;
  },

  methods: {
    ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),

    withEnterPressed(keyData) {
      this.$emit('withEnterPressed', keyData);
    },

    withValue(valueData) {
      this.$emit('withValue', valueData);
    },

    withDetailsButtonPressed() {
      let data = {};
      data["field"] = "Details";
      this.withButtonPressed(data);
    },

    withRepairButtonPressed() {
      let data = {};
      data["field"] = "Repair";
      this.withButtonPressed(data);
    },

    withPrintButtonPressed() {
      let data = {};
      data["field"] = "Print";
      this.withButtonPressed(data);
    },

    withEmailButtonPressed() {
      let data = {};
      data["field"] = "Email";
      this.withButtonPressed(data);
    },

    withRemarksButtonPressed() {
      let data = {};
      data["field"] = "Remarks";
      this.withButtonPressed(data);
    },

    withDeleteButtonPressed() {
      let data = {};
      data["field"] = "Delete";
      this.withButtonPressed(data);
    },

    withRebuildButtonPressed() {
      let data = {};
      data["field"] = "Rebuild";
      this.withButtonPressed(data);
    },

    withSelectButtonPressed() {
      let data = {};
      data["field"] = "Select";
      data["id"] = this.company.id();
      this.withButtonPressed(data);
    },

    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },

    overallStatusRow() {
      let row = new StatusDisplayRow();
      let value = this.inspection.defectType();
      row.withLabelWidth(5);
      row.withLabel("Overall Status");
      row.withValue(value);
      row.withObject(value);
      return row;
    },

    inspectionStatusRow() {
      let row = new StatusDisplayRow();
      let defect = "";
      if (this.vehicle.hasSchedule() && !this.vehicle.isEquipment()) {
        let results = this.inspection.results().find();
        let vehicleResults = results.forVehicle(this.vehicle);
        defect = vehicleResults.mostSevereUnfixedDefectType(true);
      } else {
        let checklistResults = this.inspection.checklistResults().find();
        let trailerChecklistResults = checklistResults.forVehicle(this.vehicle);
        defect = trailerChecklistResults.mostSevereUnfixedDefectType();
      }
      row.withLabelWidth(5);
      row.withLabel(this.MC.Inspection.Details.StatusLabel.value());
      row.withValue(defect);
      row.withObject(defect);
      return row;
    },

    trailerDefectStatusRow(trailer) {
      let row = new StatusDisplayRow();
      let defect = "";
      if (trailer.hasSchedule()) {
        let results = this.inspection.results().find();
        let trailerResults = results.forVehicle(trailer);
        defect = trailerResults.mostSevereUnfixedDefectType(true);
      } else {
        let checklistResults = this.inspection.checklistResults().find();
        let trailerChecklistResults = checklistResults.forVehicle(trailer);
        defect = trailerChecklistResults.mostSevereUnfixedDefectType();
      }
      row.withLabelWidth(5);
      row.withLabel(this.MC.Inspection.Details.StatusLabel.value());
      row.withValue(defect);
      row.withObject(defect);
      return row;
    }
  }
}
</script>