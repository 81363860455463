<template>

    <Row v-if="ready" :class="rowData.even() ? 'rowEven' : 'rowOdd'">
      <Column :width="3" class="rc-text-label">
        <Box>
          <Row>
            <Column :width="4" class="rc-text-centre">
              <img v-if="rowData.hasInitialMinor()" src="@/assets/2019/minor_chek.png" width="18px" />
              <img v-if="rowData.hasInitialMajor()" src="@/assets/2019/major_chek.png" width="18px" />
            </Column>
            
            <Column :width="4" class="rc-text-centre">
              <img v-if="rowData.hasEnrouteMinor()" src="@/assets/2019/minor_chek.png" width="18px" />
              <img v-if="rowData.hasEnrouteMajor()" src="@/assets/2019/major_chek.png" width="18px" />
            </Column>
  
            <Column :width="4" class="rc-text-centre">
             <img v-if="rowData.isRepaired()" src="@/assets/2019/none_chek.png" width="18px" />
            </Column>
  
            <Column :width="3" class="rc-text-centre" />
          </Row>
        </Box>
      </Column>
      <Column :width="12" class="rc-text-value">
        <Box>
          <Row>
            <Column :width="14">{{ rowData.index() }}) {{ rowData.label() }} </Column>
            <Column :width="1">
              <Button v-if="!this.rowData.isRepaired()" v-on:click="withRepairButtonPressed()">Repair</Button>
            </Column>
          </Row>
        </Box>
        <Box v-if="showMissingRequirements && this.rowData.mandatoryValue() && !this.rowData.isRepaired()">
          <Row>
            <Column :width="15">
              <ValueRow class="rc-background-minor rc-font-large" fontColour="light" >Repair required. Please repair at least one defect.</ValueRow>
            </Column>
          </Row>
        </Box>
      </Column>
    </Row>
    
  </template>
  
  <script>
  
  import { mapGetters } from 'vuex'
  
  import ContentUtils from '@/utils/ContentUtils.js';
  import StringUtils from '@/utils/StringUtils.js';

  import ScheduleQuestionOptionUpdateRow from "./ScheduleQuestionOptionUpdateRow.js";
  import Box from "@/portals/shared/library/box/Box.vue";
  import Button from "@/portals/shared/library/button/Button.vue";
  import Row from "@/portals/shared/library/box/Row.vue";
  import Column from "@/portals/shared/library/box/Column.vue";
  import ValueRow from '@/portals/shared/library/value/ValueRow.vue';
  
  export default {
    name: 'schedule-question-option-update-cell',
    components: {
      Box, Row, Column,
      Button,
      ValueRow,
    },
    props: {
      css:    { type: String, default: "" },
      showMissingRequirements: { type: Boolean, default: false },
      data: null,
    },
    data() {
      return {
        ready: false,
        rowData: new ScheduleQuestionOptionUpdateRow(),
        Contents: ContentUtils,
      }
    },
    computed: {
      ...mapGetters([
                     'domain',
                   ]),
      row: function() {
        return this.rowData;
      },
    },
    mounted: function () {
      this.rowData.data = this.data;
      this.ready = true;
    },
    methods: {
      withValue: function(kvp) {
        if (kvp) {
          kvp['id'] = this.rowData.id();
          kvp['object'] = this.rowData.object();
          this.$emit('withValue', kvp);
        }
      },

      withRepairButtonPressed() {
        let data = {}
        data["field"] = "RepairPart"
        this.withButtonPressed(data)
      },

      withButtonPressed: function (buttonData) {
        if (StringUtils.isEmpty(buttonData["id"])) {
          buttonData["id"] = this.row.id();
        }
        buttonData["key"] = this.row.key();
        this.$emit('click', buttonData);
        this.$emit('withButtonPressed', buttonData);
      },
    },
  }
  </script>