<template>
  <Row v-if="ready" borderStyle="rc-border-thin-even">
    <Column :width="15" :key="redraw">
      <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
        <Row>
          <Column :width="5">
            <LabelValueBox :labelWidth="3">
              <template slot="label">
                Name
              </template>
              <template slot="value">
                {{ this.card.name() }}
              </template>
            </LabelValueBox>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="2">
              <template slot="label">
                Number
              </template>
              <template slot="value">
                {{ this.card.number() }}
              </template>
            </LabelValueBox>
          </Column>
          <Column :width="5">
            <LabelValueBox :labelWidth="2">
              <template slot="label">
                Expiry
              </template>
              <template slot="value">
                {{ this.card.expiry() }}
              </template>
            </LabelValueBox>
          </Column>
        </Row>
        <Row>
          <Column :width="5">
            <Box>
            </Box>
          </Column>
        </Row>
        <Row> <!-- User Buttons Row-->
          <Column :width="6"></Column>

          <Column v-if="card.isStateActive()" :width="2">
          </Column>
          <Column v-if="card.isStateDeleted()" :width="2">
          </Column>

          <Column :width="1"></Column>

          <Column :width="2">
          </Column>

          <Column :width="2">
          </Column>

          <Column :width="2">
          </Column>
        </Row><!-- User Button Row-->
        
        <!-- Admin section -->
        <Row v-if="row.isAdmin()">
          <Column :width="15">
            <Box :even="row.even()">
              <Row>
                <Column>Admin Section</Column>
              </Row>
              <Row> <!-- Created and Modified By/On Row -->
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Card Id
                    </template>
                    <template slot="value">
                      {{ card.id() }}
                    </template>
                  </LabelValueBox>
                </Column>

                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Modified By/On
                    </template>
                    <template slot="value">
                      {{ card.modifiedBy().find().fullName() }}
                      {{ card.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Created By/On
                    </template>
                    <template slot="value">
                      {{ card.createdBy().find().fullName() }}
                      {{ card.createdDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>

              <Row> <!-- Button Row-->
                <Column :width="9"></Column>
                <Column :width="2">
                </Column>
                <Column :width="2">
                </Column>
                <Column :width="2">
                  <!-- <Button v-on:click="withSelectButtonPressed">Select</Button> -->
                </Column>
              </Row><!-- End Button Row-->
            </Box>
          </Column>
        </Row>
        <!-- Admin section -->

      </Box>
    </Column>
  </Row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

// import Button from "@/portals/shared/library/button/Button.vue";
import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";

import StatusCell from "@/portals/shared/cell/status/StatusDisplayCell";
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';

import CardRow from './CardRow';

export default {
  name: "card-cell",
  components: {
    Box, Row, Column,
    // StatusCell,
    LabelValueBox,
    // Button,
  },
  props: {
    showMissingRequirements: { type: Boolean, default: false },
    isAdmin: {type: Boolean, default: true},
    data: {type: Object, default: null},
  },
  data() {
    return {
      MC: new MC(),
      Connections: ConnectionUtils,
      Consts: ConstUtils,
      Content: ContentUtils,
      Strings: StringUtils,

      row: new CardRow(),
      card: null,
      ready: false,
      redraw: 1,

      StatusCell,
    }
  },
  computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'CardStorage_lastEvent',
                    ]),
  },
  watch: {
    CardStorage_lastEvent() {
      let card = this.domain.invoices().cards().findById(this.card.id());
      if (this.card.modifiedDate() < card.modifiedDate()) {
        this.row.withReport(card);
        this.card = this.row.card(this.domain);
        this.redraw++;
      }
    }
  },
  mounted: function () {
    this.row.data = this.data;
    this.card = this.row.card(this.domain);
    this.ready = true;
  },
  methods: {
    ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),

    withEnterPressed(keyData) {
      this.$emit("withEnterPressed", keyData);
    },

    withValue(valueData) {
      this.$emit('withValue', valueData);
    },

    withUpdateButtonPressed() {
      let data = {};
      data["field"] = "Update";
      this.withButtonPressed(data);
    },

    withRunButtonPressed() {
      let data = {};
      data["field"] = "Run";
      this.withButtonPressed(data);
    },

    withRestoreButtonPressed() {
      let data = {};
      data["field"] = "Restore";
      this.withButtonPressed(data);
    },

    withRemoveButtonPressed() {
      let data = {};
      data["field"] = "Remove";
      this.withButtonPressed(data);
    },

    withDetailsButtonPressed() {
      let data = {};
      data["field"] = "Details";
      this.withButtonPressed(data);
    },

    withSelectButtonPressed() {
      let data = {};
      data["field"] = "Select";
      data["id"] = this.company.id();
      this.withButtonPressed(data);
    },

    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },

    cardStateRow() {
      let row = new StatusDisplayRow();
      let value = this.card.state();
      row.withLabelWidth(3);
      row.withValueWidth(12);
      row.withLabel("State");
      row.withValue(value);
      row.withObject(this.card.stateDisplay());
      return row;
    },
  }
}
</script>