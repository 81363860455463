import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class PdfDisplayRow extends RcTableViewRow {

    static Id = "PdfDisplayRow";

    static Url = "url";

    constructor() {
        super();
        this.put(RcTableViewRow.TypeId, PdfDisplayRow.Id);
    }

    withPdfUrl(url) {
        this.put(PdfDisplayRow.Url, url);
        return this;
    }

    pdfUrl() {
        return this.getString(PdfDisplayRow.Url);
    }
}