
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class SignatureUpdateRow extends RcTableViewRow {

  static Id = "SignatureUpdateRow";
  
  static IMAGE = "image";
  static SIGNED = "signed";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, SignatureUpdateRow.Id);
  }
  
  withSignature(signature) {
    this.put(SignatureUpdateRow.IMAGE, signature.find().image());
    return this;
  }
  
  image() {
    var data = this.getString(SignatureUpdateRow.IMAGE);
    return data;
  }

  withSigned(signed) {
    this.put(SignatureUpdateRow.SIGNED, signed);
    return this;
  }

  isSigned() {
    return this.getBoolean(SignatureUpdateRow.SIGNED);
  }
}