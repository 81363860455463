<template>
    <Row v-if="ready" borderStyle="rc-border-thin-even"> 
        <Column :width="15" :key="redraw">
            <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
                <Row>
                    <Column :width="3">
                        <Box>
                            <VehicleImageDisplayCell
                            v-on:click="withButtonPressed"
                            v-on:withValue="withValue" 
                            :data="this.equipmentImageData().data" />
                        </Box>
                        <Box v-if="!equipment.isStateActive()">
                            <StatusCell :data="this.equipmentStateRow().data"></StatusCell>
                        </Box>
                    </Column>
                    <Column :width="12">
                        <Box>
                            <Row>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                            Name / Unit
                                        </template>
                                        <template slot="value">
                                            {{ this.Content.decodeText(equipment.name(), "") }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="6">
                                        <template slot="label">
                                            Serial No
                                        </template>
                                        <template slot="value">
                                            {{ this.Content.decodeText(equipment.serialNumber(), "") }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="4">
                                        <template slot="label">
                                            Year
                                        </template>
                                        <template slot="value">
                                            {{ this.Content.decodeText(equipment.year(), "") }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                            </Row>
                        </Box>
                        <Box>
                            <Row>
                                <Column :width="5">
                                    <Box>
                                        <StatusCell :data="this.equipmentStatusRow(true).data" :hint="equipment.name()"></StatusCell>
                                    </Box>
                                </Column> 
                                <Column v-if="true || equipment.isLastSafetyDateValid()" :width="5">
                                    <Box v-if="equipment.hasLastSafetyDateExpired()">
                                        <StatusCell :data="this.safetyDateDisplayRow('Expired', 'Expired').data"></StatusCell>
                                    </Box>
                                    <Box v-else-if="equipment.willLastSafetyDateExpireInWeeks(4)">
                                        <StatusCell :data="this.safetyDateDisplayRow('ExpireSoon', 'Expiring Soon').data"></StatusCell>
                                    </Box>
                                    <Box v-else-if="equipment.willLastSafetyDateExpireInWeeks(1)">
                                        <StatusCell :data="this.safetyDateDisplayRow('ExpireNext', 'Expiring this week!').data"></StatusCell>
                                    </Box>
                                    <LabelValueBox v-else :labelWidth="6">
                                        <template slot="label">
                                            Last Safety Date
                                        </template>
                                        <template slot="value">
                                            {{ this.Content.decodeText(equipment.annualDateV2().displayAs_YYYY_MM_DD(), "") }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                                <Column v-else :width="5">
                                    <LabelValueBox :labelWidth="6">
                                        <template slot="label">
                                            Last Safety Date
                                        </template>
                                        <template slot="value">
                                            {{ this.Content.decodeText(equipment.annualDateV2().displayAs_YYYY_MM_DD(), "") }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="4">
                                        <template slot="label">
                                            Make / Model
                                        </template>
                                        <template slot="value">
                                            {{ this.Content.decodeText(equipment.makeAndModel(), "") }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                            </Row>
                        </Box>
                        <Box>
                            <Row>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                            Attributes
                                        </template>
                                        <template slot="value">
                                            {{ this.Content.decodeText(equipment.summary(), "") }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                                <Column :width="5"></Column>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="4">
                                        <template slot="label">
                                            Colour
                                        </template>
                                        <template slot="value">
                                            {{ this.Content.decodeText(equipment.colour(),  "") }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                            </Row>
                        </Box>
                        <Box>  
                            <Row v-if="equipment.checklists().isNotEmpty()">
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                            Checklist
                                        </template>
                                        <template slot="value">
                                            {{ this.Content.decodeText(equipment.checklists().first().find().name(),  "") }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                            </Row>
                        </Box>
                        <Box>
                        <Row>
                            <Column :width="7"></Column>
                            <Column :width="2">
                                <Button v-on:click="withDetailsButtonPressed">Details</Button>
                            </Column>
                            <Column :width="2">
                                <Button v-on:click="withInspectionsButtonPressed">Inspections</Button>
                            </Column>
                            <Column v-if="equipment.isStateActive()" :width="2">
                                <Button v-on:click="withRemoveButtonPressed">Remove</Button>
                            </Column>
                            <Column v-if="!equipment.isStateActive()" :width="2">
                                <Button v-on:click="withRestoreButtonPressed">Restore</Button>
                            </Column>
                            <Column :width="2">
                                <Button v-on:click="withUpdateButtonPressed">Update</Button>
                            </Column>
                        </Row>
                    </Box>
                    </Column>
                </Row> 
                <Row v-if="row.isAdmin()"><!-- Admin section -->
                    <Column :width="15">
                        <Box :even="row.even()">
                            <Row>
                                <Column>Admin Section</Column>
                            </Row>

                            <Row>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                        Company Name
                                        </template>
                                        <template slot="value">
                                        {{ this.Content.decodeText(company.name(), "") }} {{ company.id() }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                        Equipment Id
                                        </template>
                                        <template slot="value">
                                        {{ equipment.id() }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                            </Row>

                            <Row>
                                <Column :width="5">
                                <Box>
                                    <Row>
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                        Modified By/On
                                        </template>
                                        <template slot="value">
                                        {{ equipment.modifiedBy().find().fullName() }}
                                        {{ equipment.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                                        </template>
                                    </LabelValueBox>
                                    </Row>
                                </Box>
                                </Column>
                                <Column :width="5">
                                <LabelValueBox :labelWidth="5">
                                    <template slot="label">
                                    Created By/On
                                    </template>
                                    <template slot="value">
                                    {{ equipment.createdBy().find().fullName() }}
                                    {{ equipment.createdDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                                    </template>
                                </LabelValueBox>
                                </Column>
                                <Column :width="5">
                                <LabelValueBox :labelWidth="5">
                                    <template slot="label">
                                    Phone
                                    </template>
                                    <template slot="value">
                                    <a :href="'tel:' + company.phone()">
                                        {{ company.phone() }}
                                    </a>
                                    </template>
                                </LabelValueBox>
                                </Column>
                            </Row>
                            <Row> <!-- Button Row-->
                                <Column :width="13" />
                                <Column :width="2">
                                    <Button v-on:click="withSelectButtonPressed">Select</Button>
                                </Column>
                            </Row><!-- End Button Row-->
                        </Box>
                    </Column>
                </Row> <!-- Admin section -->  
            </Box>
        </Column>
    </Row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import VehicleImageDisplayCell from '@/portals/shared/cell/vehicle/image/display/VehicleImageDisplayCell.vue';
import VehicleImageDisplayRow from '@/portals/shared/cell/vehicle/image/display/VehicleImageDisplayRow.js';

import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";
import Button from "@/portals/shared/library/button/Button.vue";

import StatusCell from '@/portals/shared/cell/status/StatusDisplayCell';
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';
import EquipmentRow from './EquipmentRow';


export default {
    name: "equipment-cell",
    components: {
    Box, Row, Column,
    LabelValueBox,
    Button,
    StatusCell,
    VehicleImageDisplayCell,
    },

    props: {
        showMissingRequirements: { type: Boolean, default: false },
        isAdmin: { type: Boolean, default: true },
        data: { type: Object, default: null },
    },

    data() {
        return {
            MC: new MC(),
            Connections: ConnectionUtils,
            Consts: ConstUtils,
            Content: ContentUtils,
            Strings: StringUtils,

            row: new EquipmentRow(),
            company: null,
            equipment: null,
            ready: false,
            redraw: 1,
        }
    },
    computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'EquipmentStorage_lastEvent',
                    ]),
    },  
    watch: {
        EquipmentStorage_lastEvent() {
        let equipment = this.domain.vehicles().findById(this.equipment.id());
        if (this.equipment.modifiedDate() < equipment.modifiedDate()) {
            this.row.withEquipment(equipment);
            this.equipment = this.row.equipment(this.domain);
            this.company = this.equipment.company().find();
            this.redraw++;
        }
        }
    },

    mounted: function () {
        this.row.data = this.data;
        this.equipment = this.row.equipment(this.domain);
        this.company = this.equipment.company().find();
        this.ready = true;
    },

    methods: {
    ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),

    withEnterPressed(keyData) {
      this.$emit("withEnterPressed", keyData);
    },

    withValue(valueData) {
      this.$emit('withValue', valueData);
    },

    withDetailsButtonPressed() {
      let data = {};
      data["field"] = "Details";
      this.withButtonPressed(data);
    },

    withUpdateButtonPressed() {
      let data = {};
      data["field"] = "Update";
      this.withButtonPressed(data);
    },

    withRestoreButtonPressed() {
      let data = {};
      data["field"] = "Restore";
      this.withButtonPressed(data);
    },

    withRemoveButtonPressed() {
      let data = {};
      data["field"] = "Remove";
      this.withButtonPressed(data);
    },

    withInspectionsButtonPressed() {
      let data = {};
      data["field"] = "Inspections";
      this.withButtonPressed(data);
    },

    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },

    withSelectButtonPressed() {
      let data = {};
      data["field"] = "Select";
      data["id"] = this.company.id();
      this.withButtonPressed(data);
    },

    equipmentStatusRow() {
      let row = new StatusDisplayRow();
      let value = this.equipment.stateDisplay();
      row.withValueWidth(12);
      row.withLabelWidth(5);
      row.withLabel("Status");
      row.withValue(value);
      row.withObject(value);
      return row;
    },

    equipmentStateRow() {
      let row = new StatusDisplayRow();

      row.withLabelWidth(0);
      row.withValueWidth(15);
      row.withValue("Expired");
      row.withObject("Inactive");
      return row;
    },

    safetyDateDisplayRow: function (value, hint) {
      let row = new StatusDisplayRow();
      let safetyDateDisplay = this.equipment.annualDateV2().displayAs_YYYY_MM_DD()
      row.withLabelWidth(6);
      row.withValueWidth(12);
      row.withLabel("Last Safety Date");
      row.withValue(value);
      row.withHint(hint);
      row.withObject(safetyDateDisplay);
      return row;
    },

    equipmentImageData() {
      let value = this.equipment.data;
      let row = new VehicleImageDisplayRow().withValue(value);
      return row;
    },
  }
}
</script>

